import functions from './functions.js';
import listResults from './list/listResults.js';
import pagination from './list/pagination.js';
import Slider from "./Slider";

$(() => {
    list.init();
});

const list = {
    $form: null,
    form: null,
    defaults: {
        // hasArrival: 2,
        guests: '2,0',
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
    },
    params: {},
    isHome: false,
    $listItems: null,
    init() {

        list.$form = $('.search-form');
        list.form = list.$form[0];

        if(! list.form) return;

        list.isHome = list.$form.attr('data-ishome');
        list.params.objectGroupId = list.defaults.objectGroupId = list.$form.attr('data-objectgroupid');
        list.params.objectTypeId = list.$form.attr('data-objecttypeid');
        list.params.placeId = list.$form.attr('data-placeid');
        list.params.tagCategoryId = list.$form.attr('data-tagcategoryid');
        list.params.destination = list.$form.attr('data-destination');

        list.params.pets = list.$form.attr('data-pets');
        list.params.sortBy = list.$form.attr('data-sortBy');

        list.getParams();

        if(list.params.tagCategoryId) {
            $('[name="tagCategoryId"][data-value="' + list.params.tagCategoryId + '"]').attr('checked', true);
        }

        list.$listItems = $('.list-items');

        if(! list.isHome) {
            list.initComponents();

            list.getParams();
            functions.setFormData(list.form, list.params);
            list.find();
        } else {
            functions.setFormData(list.form, list.params);
        }

        list.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            list.params = $.extend(list.params, list.getParams(), functions.getFormData($target));
            list.params._page = list.defaults._page;

            if(list.isHome) {
                //console.log($('[data-listurl]').attr('data-listurl'));
                //list.params.objectGroupId = $(e.target).attr('data-objectgroupid');
                list.params = functions.cleanParams(list.params, this.defaults);
                location = $('[data-listurl]').attr('data-listurl') + '#' + $.param(list.params);
            } else {
                functions.setUrlData(list.params, true, this.defaults);
            }

        });

        $('[name="tagCategoryId"]').on('change', e => {
            list.params._page = list.defaults._page;
            list.getParams();
            list.setTagCategoryId();
            list.find();
        });

        $(window).on('hashchange', e => {
            list.getParams();
            list.setTagCategoryId();
            list.find();
        });

    },
    setTagCategoryId() {
        let tagCategoryIds = [];
        $('[name="tagCategoryId"]').each((index, target) => {
            const $target = $(target);
            if($target[0].checked) {
                tagCategoryIds.push($target.attr('data-value'));
            }
        });
        list.params.tagCategoryId = tagCategoryIds.join(",");
    },
    getParams() {
        list.params = $.extend(list.params, list.defaults, functions.getUrlData(true));

        if(list.params.dates) {
            let dates = list.params.dates.split(' - ');
            list.params.dateFrom = dates && dates[0] ? dates[0] : null;
            list.params.dateTo = dates && dates[1] ? dates[1] : null;
        }

        list.params.destination = list.params.destination ? list.params.destination : list.$form.attr('data-destination');
        list.setDestination(list.params.destination);
    },
    setDestination(destination) {

        if(destination) {
            const destinations = destination.split(',');

            let countryIds = [],
                regionIds = [],
                rivieraIds = [],
                placeIds = [];

            destinations.forEach(destination => {
                const dest = destination.split(':');
                
                list.params.countryId = dest[0] ? list.getDestinationIds(dest[0], countryIds) : null;
                list.params.regionId = dest[1] ? list.getDestinationIds(dest[1], regionIds) : null;
                list.params.rivieraId = dest[2] ? list.getDestinationIds(dest[2], rivieraIds) : null;
                list.params.placeId = dest[3] ? list.getDestinationIds(dest[3], placeIds) : null;
            });
        } else {
            list.params.countryId = null;
            list.params.regionId = null;
            list.params.rivieraId = null;
            list.params.placeId = null;
        }
    },
    getDestinationIds(destination, array) {
        if(destination && $.inArray(destination, array)) {
            array.push(destination);
        }
        return array.join();
    },
    find() {

        list.setLoadingMode();

        $.get('/services/list', list.params).then(response => {
            response = JSON.parse(response);

            list.unsetLoadingMode();

            list.total = response.total;

            list.$listItems.html(response.html);
            if (list.total != 0) {

                $('.paginate').html(pagination({
                    currentPage: list.params._page,
                    total: list.total,
                    perPage: list.defaults.perPage,
                }));
            }

            // LOZAD
            window.observer.observe();

            listResults.getResults(list.total, list.params._page, list.defaults.perPage);

            list.initComponents();
        });

    },
    paginate(page) {
        if (page != list.params._page) {
            list.params._page = page;
        }

        functions.setUrlData(list.params, true, this.defaults);
    },
    setLoadingMode() {
        if(! list.loading) {
            list.loading = true;

            // form submit btn
            (list.$form).find('button').prop('disabled', true);

            if ( ! $('.spinner').length) {
                $('<div class="spinner d-none"><div class="spinner-border text-secondary" role="status" style="width: 4rem; height: 4rem;"><span class="sr-only">Loading...</span></div></div>').insertBefore('.list-items');
            }

            $('.spinner').removeClass('d-none');
            $('.spinner').addClass('d-flex justify-content-center');
            list.$listItems.addClass('d-none');
            $('.paginate').addClass('d-none');
        }
    },
    unsetLoadingMode() {
        if(list.loading) {
            list.loading = false;

            // form submit btn
            (list.$form).find('button').prop('disabled', false);

            $('.spinner').removeClass('d-flex justify-content-center');
            $('.spinner').addClass('d-none');
            list.$listItems.removeClass('d-none');
            $('.paginate').removeClass('d-none');

            // functions.scrollToElement(list.$listItems, 100);
        }
    },
    initComponents() {

        let listSlider = [];
        let slidesPerView = 3;
        $('[id^=list-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            listSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: length > slidesPerView,
                arrows: true,
                slidesPerView: slidesPerView,
                spacing: 0,
                duration: 1000,
                //autoplay: length > slidesPerView ? 3000 : 0,
                autoplay: 0,
                pauseOnHover: true,
                pager: false,
                breakpoints: {
                    '(max-width: 1000px)': {
                        slidesPerView: 1,
                        spacing: 0,
                    },
                },
            });
        });

        /*list.$sortBy = $('[name="sortBy"]');
        list.sortBy = list.$sortBy[0] ? list.$sortBy[0] : null;

        if(list.sortBy && list.params.sortBy) {
            list.$sortBy.val(list.params.sortBy);
        }

        list.$sortBy.on('change', e => {
            list.params = $.extend(list.params, list.getParams(), functions.getFormData(list.$sortBy));
            list.params._page = list.defaults._page;
            functions.setUrlData(list.params, true, true);
        });*/

        // register paginate        
        $('[data-page]').on('click', e => {
            e.preventDefault();
            list.paginate(+$(e.currentTarget).attr('data-page'));
        });

    },
}
